
// @ is an alias to /sr
import {
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";

import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import StepProgressBar from "@/components/atomics/StepProgressBar.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Popup from "@/components/popups/Popup.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import EditCancelPopup from "@/components/popups/contract/EditCancelPopup.vue";
import SignerEditPopup from "@/components/popups/contract/SignerEditPopup.vue";

import TemplateSignerAddPopup from "@/components/popups/templates/TemplateSignerAddPopup.vue";
import ViewerDetailPopup from "@/components/popups/contract/SignerDetailPopup.vue";

import FilePreview from "@/components/parts/FilePreview.vue";
import PdfViewer from "@/components/atomics/pdf-viewer/PdfViewer.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import EditBottomNavBar from "@/components/organisms/EditBottomNavBar.vue";

import { useI18n } from "vue-i18n";
import { mapMutations, useStore } from "vuex";
import { TemplateState } from "@/store/template/state";
import { NotificationInformation } from "@/models/contracts/NotificationInformation";

import {
  buildContractEditBreadScrumPath,
  formatThounsandNumber,
  getFilePathFromUrl,
} from "@/lib/utility/stringUtil";

import { formatLocalDate, isEmptyObject } from "@/lib/utility/common";
import { RepositoryFactory, TemplateRepository } from "@/lib/https";
import { useRoute, useRouter } from "vue-router";
import { useForm } from "vee-validate";
import useMapAndStore from "@/lib/compositional-logic/useMapAndStore";
import useContract from "./contract";
import { ContractViewer } from "@/models/contracts/ContractViewer";
import moment from "moment";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

export default defineComponent({
  name: "ContractSummary",
  components: {
    TextButton,
    FlatButton,
    HeaderTitleBar,
    StepProgressBar,
    ValidationTextField,
    Popup,
    TemplateSignerAddPopup,
    BreadScrum,
    FilePreview,
    AfterLoginPageLayout,
    PdfViewer,
    AvatarCircle,
    EditCancelPopup,
    EditBottomNavBar,
    SignerEditPopup,
    ViewerDetailPopup,
    Vue3Popper,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      selectedUsers: [] as ContractViewer[],
      viewers: [] as ContractViewer[],
      progressItems: [
        {
          name: t("template.progressAddFile"),
          event: () => router.push({ name: "Contract", params: route.params }),
        },
        {
          name: t("template.progressSigner"),
          event: () =>
            router.push({ name: "ContractSigners", params: route.params }),
        },
        {
          name: t("template.progressVars"),
          event: () =>
            router.push({ name: "ContractVars", params: route.params }),
        },
        { name: t("contract.progressConfirm") },
        { name: t("template.progressDone") },
      ],
      hours: [
        { id: "1", name: "0:00 AM", disabled: false },
        { id: "2", name: "1:00 AM", disabled: false },
        { id: "3", name: "2:00 AM", disabled: false },
        { id: "4", name: "3:00 AM", disabled: false },
        { id: "5", name: "4:00 AM", disabled: false },
        { id: "6", name: "5:00 AM", disabled: false },
        { id: "7", name: "6:00 AM", disabled: false },
        { id: "8", name: "7:00 AM", disabled: false },
        { id: "9", name: "8:00 AM", disabled: false },
        { id: "10", name: "9:00 AM", disabled: false },
        { id: "11", name: "10:00 AM", disabled: false },
        { id: "12", name: "11:00 AM", disabled: false },
        { id: "13", name: "12:00 PM", disabled: false },
        { id: "14", name: "1:00 PM", disabled: false },
        { id: "15", name: "2:00 PM", disabled: false },
        { id: "16", name: "3:00 PM", disabled: false },
        { id: "17", name: "4:00 PM", disabled: false },
        { id: "18", name: "5:00 PM", disabled: false },
        { id: "19", name: "6:00 PM", disabled: false },
        { id: "20", name: "7:00 PM", disabled: false },
        { id: "21", name: "8:00 PM", disabled: false },
        { id: "22", name: "9:00 PM", disabled: false },
        { id: "23", name: "10:00 PM", disabled: false },
        { id: "24", name: "11:00 PM", disabled: false },
      ],
      breadScrumPaths: [],
      isEdit: false,
      canDelete: false,
      onlyInformation: false,
      selectedFileIndex: 0,

      viewerEditIndex: -1,

      folderId: "",
      folderName: "",
      contractStatus: "",
    });

    const shownPopupState = reactive({
      isShowConfirmPopup: false,
      isShowAdvanced: true,
      isShowAddViewerPopup: false,
      isShowViewerDetailPopup: false,
    });

    const messageAndNotification = reactive({
      moreMessage: "",
      subject: "",
      body: "",
      emails: [] as Array<string>,
      notificationDate: 0,
      notificationTime: "",
      isErrorOfEmailTagField: "",
    });
    const notificationSection = ref(null);

    const emailField = ref("");
    const isMobile = inject("isMobile");
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const { errors, submitForm, setFieldError } = useForm({});

    const {
      editProgressBarState,
      checkingAndNavigation,
      resetState,
      backToFolder,
      saveEditContract,
      deleteDraftContract,
      showSuccessNotification,
      showErrorNotification,
      saveDraftContract,
    } = useContract();

    watch(
      () => messageAndNotification,
      (val) => {
        const { subject, body, emails } = val;
        if (subject.trim() == "" && body.trim() == "" && emails.length == 0) {
          setFieldError("subject", "");
          setFieldError("body", "");
          messageAndNotification.isErrorOfEmailTagField = "";
        }

        if (messageAndNotification.notificationDate != 0 &&
          moment(messageAndNotification.notificationDate).format(
            "YYYY-MM-DD"
          ) == moment(Date.now()).format("YYYY-MM-DD")
        ) {
          const currentDate = moment(Date.now());
          const currentHour = currentDate.hour() + 1;
          for (let i = 0; i < currentHour; i++) state.hours[i].disabled = true;
        } else {
          for (let i = 0; i < state.hours.length; i++)
            state.hours[i].disabled = false;
        }
      },
      { deep: true }
    );

    const { createContracts, updateContracts, removeDraftContract } =
      RepositoryFactory.getRepository<TemplateRepository>(TemplateRepository);

    const contractInformation = store.getters[
      "template/getTemplateInfo"
    ] as TemplateState;

    const { createContractInformationFormData } = useMapAndStore();

    const setAllowTransfer = async (isAllow: boolean) => {
      await store.dispatch("template/setAllowTransfer", isAllow);
    };

    const setSingleViewer = async (viewerInformation: any) => {
      if (state.viewerEditIndex >= 0)
        state.viewers[state.viewerEditIndex] = {
          ...viewerInformation,
        } as never;
      await updateViewers();
      state.viewerEditIndex = -1;
    };

    const updateViewers = async () => {
      const viewers = state.viewers.map((user: any, index: number) => {
        const {
          id = "",
          userId,
          profilePicture = "",
          firstName,
          lastName,
          email,
          companyName = "",
          accessCode = "",
        } = user;
        return new ContractViewer(
          id,
          index + 1,
          userId,
          profilePicture,
          firstName,
          lastName,
          email,
          companyName,
          accessCode
        );
      });
      await store.dispatch("template/setViewers", viewers);
    };

    // Draft section
    const onSave = async () => {
      if (await isNotificationFieldValidated()) {
        await saveDraftContract(storeMessageAndNotification);
      }
    };

    const createContractFullInformation = async () => {
      const contractJsonAsString = store.getters[
        "template/getContractJson"
      ] as string;

      const contractFullInformation = await createContractInformationFormData(
        contractInformation.files,
        contractJsonAsString
      );

      return contractFullInformation;
    };

    //Edit Section
    const onEdit = async () => {
      if (await isNotificationFieldValidated()) {
        await storeMessageAndNotification();
        await saveEditContract(setFulfilledStatusContract);
      }
    };
    // Back and next section
    const back = () => {
      router.push({
        name: "ContractVars",
        params: route.params,
        query: route.query,
      });
    };

    const next = async () => {
      if (await isNotificationFieldValidated()) {
        await storeMessageAndNotification();
        await setFulfilledStatusContract();

        const contractFullInformation = await createContractFullInformation();

         const accountInfo = store.getters[
          "header/getAccountInfo"
        ];
        try {
          const res = contractInformation.id
            ? await updateContracts(contractFullInformation)
            : await createContracts(contractFullInformation);
          if (res) router.push({ name: "ContractDone", query: {
            contractId: res.id,
            canSign: String(accountInfo.email === contractInformation.contractSigners[0].email)
          } });
        }
        catch(err: any) {
          shownPopupState.isShowConfirmPopup = false;
          showErrorNotification(err.data.message);
        }
      }
    };

    //Notification's Validation
    const isNotificationFieldValidated = async () => {
      if (isAnyNotificationFieldBeInput()) {
        await submitForm();
        const isEmptyEmailField = isEmptyEmailTagField();
        if (!isEmptyObject(errors.value) || isEmptyEmailField) {
          if (isEmptyEmailField)
            messageAndNotification.isErrorOfEmailTagField =
              t("errors.required");
          shownPopupState.isShowConfirmPopup = false;
          scrollToNotificationSection();
          return false;
        }
      }

      return true;
    };

    const isAnyNotificationFieldBeInput = () => {
      return messageAndNotification.subject.trim() != "" ||
        messageAndNotification.body.trim() != "" ||
        !isEmptyEmailTagField()
        ? true
        : false;
    };

    const isEmptyEmailTagField = () => {
      return messageAndNotification.emails.length <= 0;
    };

    const scrollToNotificationSection = () => {
      if (notificationSection.value) {
        (notificationSection.value as any)?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    };

    const storeMessageAndNotification = async () => {
      await Promise.all([
        store.dispatch(
          "template/setMessage",
          messageAndNotification.moreMessage
        ),
        store.dispatch("template/setNotification", getNotification()),
      ]);
    };

    const getNotification = () => {
      const { subject, body, emails, notificationDate, notificationTime } =
        messageAndNotification;
  
      const notificationDateAndTime = messageAndNotification.notificationDate != 0 ? new Date(notificationDate).setHours(
        parseInt(notificationTime) - 1
      ) : 0
      return new NotificationInformation(
        subject,
        body,
        emails,
        notificationDateAndTime
      );
    };

    const setFulfilledStatusContract = async () => {
      await store.dispatch("template/setStatus", 1);
    };

    //create as Template
    const createAsTemplate = async () => {
      await setTemplateFormat();
      const contractFullInformation = await createContractFullInformation();

      await createContracts(contractFullInformation).then((res) => {
        setContractFormat();
        showSuccessNotification(
          `${contractInformation.title} ${t(
            "notification.templateCreateSuccess"
          )}`
        );
      });
    };

    const setTemplateFormat = async () => {
      await store.dispatch("template/setIsTemplate", true);
    };

    const setContractFormat = async () => {
      await store.dispatch("template/setIsTemplate", false);
    };

    onMounted(() => {
      checkingAndNavigation(contractInformation);
      const {
        contractViewers,
        message,
        notification,
        folderId,
        folderName,
        title,
      } = contractInformation;

      state.folderId = folderId;
      state.folderName = folderName ?? "";
      state.contractStatus = route.params.status as string;

      setViewers(contractViewers ?? []);
      setMessage(message ?? "");
      setNotification(notification);
      state.canDelete = route.params.canDelete === "false" ? false : true;

      state.isEdit = route.query.isEdit === "true";
      state.onlyInformation = route.query.onlyInformation === "true";
      if (state.isEdit) {
        state.progressItems = editProgressBarState();
        const folderPath = buildContractEditBreadScrumPath(
          folderId ?? "",
          folderName ?? ""
        );
        state.breadScrumPaths = [
          { text: folderName, to: folderPath },
          { text: title, to: "#" },
        ] as never;
      }
    });

    const setViewers = (viewerLists: Array<any>) => {
      state.viewers = viewerLists.map((viewer: any, index: number) => {
        const {
          firstName,
          lastName,
          email,
          profilePicture = "",
          companyName,
          id = "",
          order = index + 1,
          userId,
          accessCode = "",
        } = viewer;
        return new ContractViewer(
          id,
          order,
          userId,
          profilePicture,
          firstName,
          lastName,
          email,
          companyName,
          accessCode
        );
      });
    };

    const setMessage = (message: string) => {
      messageAndNotification.moreMessage = message;
    };

    const setNotification = (notification: NotificationInformation) => {
      const { subject, body, emails, notificationDate } = notification;

      messageAndNotification.subject = subject ?? "";
      messageAndNotification.body = body ?? "";
      messageAndNotification.emails = emails ?? [];

      if (subject && body) {
        const dateAndTimeNotification = new Date(notificationDate);

        messageAndNotification.notificationDate = Date.parse(
          dateAndTimeNotification.toDateString()
        );
        messageAndNotification.notificationTime = (dateAndTimeNotification
          .getHours() + 1)
          .toString();
      }
    };

    return {
      ...toRefs(state),
      ...toRefs(shownPopupState),
      isMobile,
      notificationSection,
      ...contractInformation,
      getFilePathFromUrl,
      updateViewers,
      formatLocalDate,
      formatThounsandNumber,
      back,
      setAllowTransfer,
      next,
      createAsTemplate,
      onSave,
      deleteDraftContract,
      onEdit,
      backToFolder,

      ...toRefs(messageAndNotification),
      emailField,

      resetState,
      setSingleViewer,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes("/contract")) {
      this.resetState();
    }
    next();
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),

    showAddSignerPopup(): void {
      this.selectedUsers = this.viewers;
      this.isShowAddViewerPopup = true;
    },
    callViewerDetails(payload: any): void {
      this.selectedUsers = payload.map(
        ({
          order = 0,
          id = "",
          userId = "",
          profilePicture = "",
          firstName = "",
          lastName = "",
          email = "",
          companyName = "",
          accessCode = "",
        }) =>
          new ContractViewer(
            id,
            order,
            userId,
            profilePicture,
            firstName,
            lastName,
            email,
            companyName,
            accessCode
          )
      );
      this.isShowAddViewerPopup = false;
      this.isShowViewerDetailPopup = true;
    },
    onAddViewers(payload: any): void {
      this.viewers = payload;
      this.updateViewers();
      this.isShowViewerDetailPopup = false;
    },
    backAddViewer(payload: any): void {
      this.isShowViewerDetailPopup = false;
      this.selectedUsers = payload;
      this.isShowAddViewerPopup = true;
    },
    removeViewer(user: any) {
      const index = this.viewers.findIndex(
        (element: any) => element.id == user.id
      );
      if (index >= 0) this.viewers.splice(index, 1);
      this.updateViewers();
    },

    downloadFile(file: any) {
      const link = document.createElement("a");
      link.href = file.path;
      link.target = "_blank";
      link.click();
    },

  },
});
